<template>
  <v-row>
    <v-col>
      <v-card
        outlined
        :loading="form.loading || allMembers.length == 0"
        :disabled="form.loading"
      >
        <v-card-text>
          Add Member Links
          <v-autocomplete
            label="Member"
            v-model="form.member_id"
            :items="allMembers"
            item-text="name"
            item-value="id"
            v-if="allMembers.length !== 0"
            @change="fetchLinks"
          ></v-autocomplete>

          <v-form ref="links" v-if="form.data">
            <v-row v-for="(link, i) in form.data" :key="i">
              <v-col>
                <v-autocomplete
                  label="App Name"
                  v-model="link.app_name"
                  :items="appcategory"
                  :rules="form.rules.app"
                ></v-autocomplete>
              </v-col>
              <v-col>
                <v-textarea
                  label="URL"
                  v-model="link.url"
                  messages="Make sure URL start with https://"
                  :rules="form.rules.url"
                >
                  <template v-slot:append-outer>
                    <v-btn
                      icon
                      color="deep-orange accent-2"
                      @click="handleAppLink('remove', i)"
                      v-if="form.data.length > 1"
                    >
                      <v-icon>mdi-trash-can</v-icon>
                    </v-btn>
                  </template>
                </v-textarea>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-tooltip right transition="slide-x-transition">
                <template v-slot:activator="{ on }">
                  <v-btn
                    depressed
                    v-on="on"
                    color="indigo accent-2"
                    @click="handleAppLink('add', null)"
                  >
                    <v-icon color="white">mdi-link-variant-plus</v-icon>
                  </v-btn>
                </template>

                <span>Add App</span>
              </v-tooltip>

              <v-spacer></v-spacer>

              <v-tooltip left transition="slide-x-reverse-transition">
                <template v-slot:activator="{ on }">
                  <v-btn
                    depressed
                    v-on="on"
                    color="indigo accent-2"
                    @click="saveLinks"
                  >
                    <v-icon color="white">mdi-content-save</v-icon>
                  </v-btn>
                </template>

                <span>Save Data</span>
              </v-tooltip>
            </v-row>
          </v-form>
          <v-alert
            dense
            prominent
            text
            icon="mdi-information-variant"
            color="info"
            v-if="form.isEmpty && !form.data"
          >
            <v-row no-gutters class="align-center">
              Member has no links yet.
              <v-spacer></v-spacer>
              <v-btn
                depressed
                color="primary"
                @click="handleAppLink('new', null)"
              >
                <span>add link</span>
              </v-btn>
            </v-row>
          </v-alert>
        </v-card-text>
      </v-card>
    </v-col>

    <v-snackbar v-model="snackbar.show" :color="snackbar.color">
      <v-icon left>
        {{ snackbar.icon }}
      </v-icon>
      {{ snackbar.text }}

      <template v-slot:action>
        <v-btn text @click="snackbar.show = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </v-row>
</template>

<script>
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      form: {
        isEmpty: false,
        loading: false,
        member_id: null,
        data: null,
        rules: {
          app: [(v) => !!v || "App Name is required"],
          url: [(v) => !!v || "Link is required"],
        },
      },
      del: {
        dialog: false,
        loading: false,
        data: null,
      },
      appcategory: [
        "Tokopedia",
        "Bukalapak",
        "Shopee",
        "Lazada",
        "Facebook",
        "Instagram",
        "TikTok Shop",
        "WhatsApp",
      ],
      snackbar: {
        show: false,
        color: null,
        text: null,
        icon: null,
      },
    };
  },
  methods: {
    ...mapActions(["addLink"]),
    handleAppLink(act, i) {
      switch (act) {
        case "add": {
          // console.log(this.form.data);
          this.form.data.push({ app_name: null, link: null, isFromDB: false });
          // console.log(this.form.data);
          break;
        }
        case "remove": {
          this.form.data.splice(i, 1);
          break;
        }
        case "new": {
          if (!this.form.data) {
            this.form.data = [{ app_name: null, link: null, isFromDB: false }];
          }
        }
      }
    },
    async fetchLinks() {
      let id = this.form.member_id;
      this.form.loading = true;
      await axios
        .get(`${process.env.VUE_APP_API_URL}/api/member/${id}/links`, {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          // console.log(response.data)
          if (response.data.length == 0) {
            this.form.loading = false;
            this.form.isEmpty = true;
          } else {
            this.form.loading = false;
            this.form.data = [
              { app_name: null, link: null },
            ]
          }
        });
    },
    saveLinks() {
      this.form.loading = true;
      let links = this.form.data;
      let id = this.form.member_id;
      let isValid = this.$refs.links.validate();

      if (isValid) {
        for (let i = 0; i < links.length; i++) {
          let data = {
            member_id: id,
            app_name: links[i].app_name,
            url: `${links[i].url}`,
          };
          this.addLink(data)
            .then(() => {
              if (i == links.length - 1) {
                this.form.loading = false;
                this.showNotification("success");
              }
            })
            .catch((err) => {
              console.log(err);
              this.form.loading = false;
              this.showNotification("error");
            });
        }
      } else {
        this.form.loading = false;
        this.showNotification("error");
      }
    },
    showNotification(status) {
      this.snackbar.show = true
      switch (status) {
        case "success":
          this.snackbar.color = "success";
          this.snackbar.text = "Successfully Added";
          this.snackbar.icon = "mdi-check-circle";
          break;
        case "error":
          this.snackbar.color = "error";
          this.snackbar.text = "Error Occured";
          this.snackbar.icon = "mdi-close-circle";
          break;
      }
    },
  },
  computed: {
    ...mapGetters(["allMembers"]),
  },
};
</script>